import styled from 'styled-components';
import { Display1, Header5 } from '../../atoms/Fonts';

export const MissingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Display1)`
  color: ${(props) => props.theme.colors.text.black};
  text-align: center;
`;

export const Subtitle = styled(Header5)`
  color: ${(props) => props.theme.colors.text.black};
  margin: 8px 0 24px 0;
  text-align: center;
`;
