import Link from 'next/link';
import Button from '../../atoms/Button';
import { MissingPageContainer, Subtitle, Title } from './MissingPage.styled';

interface MissingPageProps {}

const MissingPage = (props: MissingPageProps) => {
  return (
    <MissingPageContainer>
      <Title>Page Does Not Exist</Title>
      <Subtitle>The page you are looking for can not be found.</Subtitle>
      <Link href='/'>
        <Button variant='primary' size='lg'>
          RETURN TO HOMEPAGE
        </Button>
      </Link>
    </MissingPageContainer>
  );
};

export default MissingPage;
